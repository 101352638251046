import React, { useState } from "react"
import { Table } from "reactstrap"
import "./details.scss"
import { Button, Input } from "rsuite"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowNotification } from "common/ShowNotification"
import { useSelector } from "react-redux"
import axios from "axios"
import { ExpressUrl } from "common/ApiRoute"

function Details({ data, token, id, fragment}) {
  // const [update, setUpdate] = useState(data)
     const [newdata,setData] =useState({})
  const [update, setUpdate] = useState({
    token: token,
    mnid: data["notice_unq_id"],
    rowid: id,
  }) 
     const pdfStrategy = useSelector((state=> state?.DraftNotice?.items?.pdf_generation_strategy))


  const handleSubmit = () => {
       const payloadObj = {
        noticeUniqueId:update.mnid,
        "data": newdata
       }
     if(pdfStrategy === "EXPRESS") {
        axios.post(`${ExpressUrl}notices/data`,payloadObj) 
        .then((res)=>{
          if (res?.data?.code === "200") {
            ShowNotification(res?.data?.response, "success")
          } else {
            ShowNotification(res?.data?.response, "danger")
          }
        })
        .catch((err)=>{
            ShowNotification("Something went wrong!","danger")
        })
     }
     else {
      ApiCall(update, "user/update-party-detail", false, result => {
        if (result?.data?.response === "success") {
          ShowNotification(result?.data?.message, "success")
        } else {
          ShowNotification(result?.data?.message, "danger")
        }
      })
     }

   
  }

  return (
    <React.Fragment>
      <div className="table-responsive">
        <Table bordered className="detailsView">
          <tbody>
            {Object.keys(data).map((value, index) => {
              return (
                <>
                  {value != "userNotes" &&
                    value != "active_On" &&
                    value != "esc_rsv_status" &&
                    value != "Id" &&
                    value != "notice_ID" &&
                    value != "overdue_On" &&
                    value != "resolved_On" &&
                    value != "escalated_On" &&
                    value != "user_notice_path" &&
                    value != "UserId" &&
                    value != "token" &&
                    value != "PayLinkExpire" &&
                    value != "payToken" &&
                    value != "notice_status" && (
                      <tr key={index}>
                        <th>
                          {value.replace(/_/g, " ") == "notice unq id"
                            ? "Notice ID"
                            : value.replace(/_/g, " ") == "notesAttachment"
                            ? "Notes"
                            : value.replace(/_/g, " ") == "batchName"
                            ? "Batch Name"
                            : value.replace(/_/g, " ")}
                        </th>
                        <td>
                          {fragment === "draft-notice" ? (
                            <Input
                              defaultValue={data[value]}
                              readOnly={
                                value === "notice_unq_id" ||
                                value === "date_of_generation" ||
                                value === "letterhead" ||
                                value === "signature" ||
                                value === "username" ||
                                value === "activatedOn"
                              }
                              onChange={(e) =>{
                                setUpdate({ ...update, [value]: e })
                                setData({ ...newdata, [value]: e })
                              }
                              
                              }
                            />
                          ) : (
                            data[value]
                          )}
                        </td>
                      </tr>
                    )}
                </>
              )
            })}
          </tbody>
        </Table>
        {fragment === "draft-notice" && (
          <Button
            appearance="primary"
            color="blue"
            size="sm"
            className="my-4"
            onClick={() => handleSubmit()}
          >
            Update
          </Button>
        )}
      </div>
    </React.Fragment>
  )
}

export default Details
