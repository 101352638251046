import EventDetailIcon from '@rsuite/icons/EventDetail';
import VisibleIcon from '@rsuite/icons/Visible';
import axios from 'axios';
import { DownloadApiCall } from 'common/ActionApiCall/DownloadApiCall';
import { ApiRoute, RouteWithoutApi } from 'common/ApiRoute';
import SearchInput from 'common/SearchInput';
import useWindowDimensions from 'common/useWindowDimensions';
import WhisperTooltip from 'components/Common/Whisper/WhisperTooltip';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Label, Row } from 'reactstrap';
import { CheckPicker, IconButton, SelectPicker, Table } from 'rsuite';

const api_url = ApiRoute
const { Column, HeaderCell, Cell } = Table
function ReportRefTable() {
  const [search, setSearchState] = useState({ submit: false, value: "" })
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [request, setRequest] = useState({ user_id: null, notice_id: [] })
  const [selectdatauser, setSelectDataUser] = useState([])
  const [selectdatanotice, setSelectDataNotice] = useState([])
  const { height } = useWindowDimensions()
  const token = JSON.parse(localStorage.getItem("data"))?.token

  const setSearch = updatedSearch => {
    setSearchState(prev => ({ ...prev, ...updatedSearch }))
  }

  const fetchRefReport = useCallback(async () => {
    if (!request.user_id || !request.notice_id.length) {
      console.warn("User and Notice must be selected before searching.")
      return
    }

    setLoading(true)
    try {
      const response = await axios.post(`${api_url}refIdReport`, {
        token,
        searchValue: search?.value,
        user_id: request.user_id,
        notice_id: request.notice_id,
      })
      setData(response.data.responseData || [])
    } catch (error) {
      console.error(
        "Error fetching reference report:",
        error.response?.data || error.message
      )
    } finally {
      setLoading(false)
    }
  }, [token, search?.value, request.user_id, request.notice_id])

  useEffect(() => {
    if (search.submit) {
      fetchRefReport()
    }
  }, [search.submit, fetchRefReport])

  const AllFetchUserBySearch = useCallback(
    async e => {
      if (e.length < 3) return

      try {
        const response = await axios.post(`${api_url}onChangeUserSelect`, {
          token,
          search_text: e,
        })
        const users = response.data.responseData.map(user => ({
          label: user.organization_name || user.username || user.email,
          value: user.id,
        }))
        setSelectDataUser(users)
      } catch (error) {
        console.error(
          "Error fetching users:",
          error.response?.data || error.message
        )
      }
    },
    [token]
  )

  useEffect(() => {
    if (!request.user_id) {
      setSelectDataNotice([])
      return
    }

    const fetchNotices = async () => {
      try {
        let formData = new FormData()
        formData.append("token", token)
        formData.append("user_id", request.user_id)

        const response = await axios.post(
          `${RouteWithoutApi}getAllNoticeIdName`,
          formData
        )

        const notices = response.data.responseData.map(notice => ({
          label: notice.notice_type,
          value: notice.notice_id,
        }))

        setSelectDataNotice(notices)

        const validNotices = request.notice_id.filter(id =>
          notices.some(notice => notice.value === id)
        )
        setRequest(prev => ({
          ...prev,
          notice_id: validNotices.length ? validNotices : [],
        }))
      } catch (error) {
        console.error(
          "Error fetching notices:",
          error.response?.data || error.message
        )
      }
    }

    fetchNotices()
  }, [request.user_id, token])
  const downloadClickNoticePdf = file => {
    const request = {
      token: token,
      folder: "userGnrtdNotice",
      filename: file,
    }
    DownloadApiCall(request, "download/single-download", "_blank")
  }
  return (
    <div className="table-responsive" style={{ fontSize: "0.9em" }}>
      <div style={{ marginBottom: "20px" }}>
        <Row>
          <Col md={4}>
            <Label>Select User:</Label>
            <SelectPicker
              placeholder="Select User"
              onSearch={AllFetchUserBySearch}
              value={request?.user_id}
              block
              data={selectdatauser}
              onChange={value =>
                setRequest(prev => ({ ...prev, user_id: value }))
              }
            />
          </Col>
          <Col md={4}>
            <Label>Select Notices:</Label>
            <CheckPicker
              placeholder="Select Notices"
              value={request?.notice_id}
              block
              data={selectdatanotice}
              disabled={!request.user_id}
              onChange={value =>
                setRequest(prev => ({ ...prev, notice_id: value }))
              }
              style={{ width: "100%" }}
            />
          </Col>
          <Col md={4}>
            <Label>Reference/Notice ID/Name/Email:</Label>
            <SearchInput
              placeholder="Reference/Notice ID/Name/Email"
              search={search}
              setSearch={setSearch}
              disabled={!request.user_id || !request.notice_id.length}
            />
          </Col>
        </Row>
      </div>

      <Table
        height={height - 350}
        data={data}
        wordWrap="break-word"
        loading={loading}
      >
        <Column width={40} align="center">
          <HeaderCell>Sr.No.</HeaderCell>
          <Cell>{(rowdata, dataKey) => <span>{dataKey + 1}</span>}</Cell>
        </Column>
        <Column width={100} align="center">
          <HeaderCell>Notice ID</HeaderCell>
          <Cell dataKey="notice_id" />
        </Column>
        <Column width={100} align="center">
          <HeaderCell>Reference ID</HeaderCell>
          <Cell dataKey="Reference_ID" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Notice</HeaderCell>
          <Cell dataKey="table_name" />
        </Column>
        <Column width={150}>
          <HeaderCell>Date of Notice</HeaderCell>
          <Cell dataKey="date_of_notice" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Batch Name</HeaderCell>
          <Cell dataKey="batchName" />
        </Column>
        <Column width={150}>
          <HeaderCell>Action</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="View Track"
                >
                  <Link
                    target="_blank"
                    to={{
                      pathname: "/admin/track/" + btoa(rowdata.noticeId),
                      state: { prevPath: location.pathname },
                    }}
                  >
                    <IconButton
                      size="xs"
                      appearance="primary"
                      color="blue"
                      icon={<EventDetailIcon />}
                    />
                  </Link>
                </WhisperTooltip>
                &nbsp;
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="View Notice PDF"
                >
                  <IconButton
                    size="xs"
                    appearance="primary"
                    color="orange"
                    icon={<VisibleIcon />}
                    onClick={() =>
                      downloadClickNoticePdf(rowdata?.user_notice_path)
                    }
                  />
                </WhisperTooltip>
              </>
            )}
          </Cell>
        </Column>
      </Table>
    </div>
  )
}

export default ReportRefTable
